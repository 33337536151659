import {
    _
} from 'wgnet-awesomejs';
import BaseDescriptionField from 'registration/js/base/fields/description';

import mobile from 'wgnet-awesomejs/lib/mobile';

import template from 'registration/templates/dark/fields/description.html';

const DescriptionField = BaseDescriptionField.extend({
    name: 'description-field',
    template,
    afterInitialize() {
        BaseDescriptionField.prototype.afterInitialize.call(this);
        this.$inputWrapper = this.$fieldset.find('.js-input-wrapper');
        this.$input = this.$fieldset.find('input');
        this.$input.on('focus', _.bind(this.showDescription, this));
        this.$input.on('blur', _.bind(this.hideDescription, this));
    },
    showDescription(event = {}) {
        const st = this.$fieldset.data('error-state');
        const er = this.$fieldset.data('suggested-state');

        if (st || er || mobile().useMobile) {
            return;
        }
        if (this.$input.is(':focus') || this.$input[0] === event.currentTarget) {
            this.$hint.removeClass('hidden');
        }
    },
});

export default DescriptionField;
