import { $, _ } from 'wgnet-awesomejs';
import ChallengeModel from 'wgnet-awesomejs/base/challenge_model';
import mobile from 'wgnet-awesomejs/lib/mobile';
import URI from 'urijs';

import BaseModel from './basic_form_model';

const Model = BaseModel.extend({
    featureKeyPrefix: 'Settings.Signin.challenge',
    buildUrl() {
        const url = ChallengeModel.prototype.buildUrl.call(this);
        if (window.location.search) {
            url.addQuery(URI.parseQuery(window.location.search));
        }
        return url;
    },
    getChallengeFeatureOptions() {
        const key = this.featureKeyPrefix || (this.view && this.view.featureKeyPrefix);
        if (!key) {
            return false;
        }
        return {
            feature: _.extract(`${key}.feature`),
            url: _.extract(`${key}.url`),
            syncStorageEnabled: _.extract(`${key}.storageSync`),
            allowedChallengeTypes: _.extract(`${key}.types`),
            errorRequestsLimit: _.extract(`${key}.ErrorRequestsLimit`)
        };
    },
    sync(method, model, options) {
        const attrs = model.toJSON(options);
        attrs.username = attrs.login;
        delete attrs.login;
        options.attrs = attrs;
        return BaseModel.prototype.sync.call(this, method, model, options);
    },
    set(attrs, options) {
        if (_.isObject(attrs) && !_.isEmpty(attrs)) {
            if (attrs.login) {
                attrs.login = $.trim(attrs.login);
            }
        }
        return ChallengeModel.prototype.set.call(this, attrs, options);
    },
    save(...args) {
        this.set('form_token', _.extract('Settings.Registration.formTokenRaw'));
        this.set('feconf', JSON.stringify({
            form_type: mobile().useMobile ? 'mobile' : 'desktop',
            form_width: window.innerWidth,
            form_height: window.innerHeight
        }));
        return ChallengeModel.prototype.save.call(this, ...args);
    }
});
export default Model;
