import BaseCountry from 'registration/js/base/fields/country_code';

import template from 'registration/templates/dark/fields/country_code.html';

const CountryCodeField = BaseCountry.extend({
    template,
    classModifiersMap: Object.assign(BaseCountry.prototype.classModifiersMap, {
        opened: 'custom-selector__open',
        focused: 'custom-selector__focus',
    }),
});

export default CountryCodeField;
