import URI from 'urijs';

import BaseCaptchaField from 'registration/js/base/fields/captcha';
import mobile from 'wgnet-awesomejs/lib/mobile';

import template from 'registration/templates/dark/fields/captcha.html';

const CaptchaField = BaseCaptchaField.extend({
    template,
    getImageSrc({ url }) {
        if (mobile().useMobile) {
            return URI(url).addSearch('theme', 'dark_mobile').toString();
        }
        return BaseCaptchaField.prototype.getImageSrc.call(this, { url });
    },
});

export default CaptchaField;
