import BaseEulaField from 'registration/js/base/fields/eula';

import template from 'registration/templates/dark/fields/eula.html';

const EulaField = BaseEulaField.extend({
    template,
    openIcoClass: 'tooltip-ico-game__open'
});

export default EulaField;
