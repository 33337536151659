import BaseExternalView from 'registration/js/base/external_view';
import WaitingView from 'registration/js/dark/waiting_view';

import services from 'registration/templates/dark/services.html';
import serviceComplete from 'registration/templates/dark/service_complete.html';
import serviceIdmeComplete from 'registration/templates/dark/service_idme_complete.html';
import serviceGettokenMessage from 'registration/templates/base/service_gettoken_message.html';

const ExternalServiceView = BaseExternalView.extend({
    templates: {
        services,
        serviceComplete,
        serviceIdmeComplete,
        serviceGettokenMessage
    },
    initExternalServiceMessage($el) {
        this.initSpin($el);
    },
    hiddenSpinnerOptions: {
        modifiers: ['big', 'light']
    },
    spinnerOptions: {
        modifiers: ['big', 'dark']
    },
    WaitingView,
});

export default ExternalServiceView;
