import mobile from 'wgnet-awesomejs/lib/mobile';

import BaseWaitingView from 'registration/js/base/base_waiting_view';

const DarkWaitingView = BaseWaitingView.extend({
    spinnerModifiers: [
        'light',
        mobile().useMobile ? '' : 'biggest',
    ].filter(Boolean),
});

export default DarkWaitingView;
