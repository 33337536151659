import BaseLoginExternalView from 'registration/js/base/login_external_view';

import services from 'registration/templates/dark/login_services.html';
import serviceComplete from 'registration/templates/dark/service_complete.html';
import serviceGettokenMessage from 'registration/templates/base/service_gettoken_message.html';

const LoginExternalView = BaseLoginExternalView.extend({
    templates: {
        services,
        serviceComplete,
        serviceGettokenMessage
    },
    initExternalServiceMessage($el) {
        this.initSpin($el);
    },
    spinnerOptions: {
        modifiers: ['dark', 'big']
    }
});

export default LoginExternalView;
