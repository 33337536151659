import Application, {
    _
} from 'wgnet-awesomejs';
import URI from 'urijs';
import 'url-polyfill';
import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import pushGaEventNew, {
    EVENTS_CATEGORIES,
    GTM_EVENTS
} from 'registration/js/lib/googletagmanager/new_events';
import { openLoginOnInit } from 'registration/js/lib/get_splittest_flow';

import ExternalView from './external_view';
import BasicExternalModel, { setSignInExternalData } from './basic_external_model';
import {
    EXTERNAL_SIGNIN_STARTED_EVENT,
    TABS_STATE,
    EXTERNAL_SWITCH_FORM_EVENT
} from './constants';

const LoginExternalView = ExternalView.extend({
    tab: TABS_STATE.LOGIN,
    elPrefix: '.js-registration-signin',
    setSignInExternalData,

    settings() {
        return _.extract('Settings.Signin', {});
    },
    makeContext() {
        const providers = this.services().providers;
        const buttons = this.services().providersUI;
        const providerLists = buttons.reduce((data, provider) => {
            if (providers.indexOf(provider) > -1) {
                data.main.push({
                    key: provider,
                    name: this.providerNames[provider]
                });
            }
            return data;
        }, { main: [], verification: [] });
        this.enebledProviders = providers;
        this.renderContext = {
            providers: providerLists.main,
            enableProviderButtons: providerLists.main.length > 0
        };
    },
    bindEvents() {
        this.delegateEvents({
            'click .js-registration-signin-external-service-select-provider': 'select',
            'click .js-registration-signin-external-service-cancel': 'onCancel'
        });

        Application.on(EXTERNAL_SIGNIN_STARTED_EVENT, this.externalStart, this);
    },
    initModel() {
        const settings = this.settings();
        this.model = new BasicExternalModel({
            game_port: settings.externalProvider.gamePort,
            game: settings.externalProvider.game,
            registration_flow: settings.registrationFlow,
            form_token: this.formTokenRaw,
            external: settings.externalProvider.code
        }, {
            getDataUrl: settings.urls.signinExternalUrl,
            oauthAuthorizeUrl: settings.urls.oauthAuthorizeUrl,
            openIdAuthorizeUrl: settings.urls.openIdAuthorizeUrl,
            openIdProviders: settings.externalServices.openIdProviders
        });
    },
    afterInitialize() {
        const settings = this.settings();
        const providerCode = settings.externalProvider.code;
        const query = URI.parseQuery(window.location.search);
        const queryError = query[this.errorQueryParameter()];


        this.render();
        if (query.external && openLoginOnInit()) {
            Application.trigger(EXTERNAL_SIGNIN_STARTED_EVENT, query.external);
        } else if (settings.externalSigned && providerCode) {
            this.parent.onTabSwitch(null, this.tab);
            this.externalContinue(providerCode);
        } else if (providerCode) {
            Application.trigger(EXTERNAL_SIGNIN_STARTED_EVENT, providerCode);
        } else if (queryError) {
            this.onQueryError(queryError);
        }
    },
    getDataComplete(result = {}) {
        if (result.success_url) {
            this.redirectTo(result.success_url);
        } else {
            this.parent.triggerCommonError(TABS_STATE.LOGIN, 'error', {
                errors: ['service_unavailable'],
                errorPrefix: this.errorPrefix,
                errorSuffix: this.currentProvider,
                warning: true
            });
        }
    },
    onFormError(error, errorsContext, provider) {
        let showError = _.isString(error) ? error : _.extract(error, '__all__.0', error);

        if (showError === 'already_authenticated') {
            this.redirectTo(errorsContext);
            return;
        }

        if (error === 'registration_proposed' && errorsContext) {
            showError = 'registration_proposed_provider';
            this.setSignInExternalData(provider, errorsContext);
            this.lastResult = {
                error,
                data: errorsContext,
                provider
            };
        }
        ExternalView.prototype.onFormError.call(this, showError, errorsContext, provider);
    },
    switchToReg() {
        Application.trigger(EXTERNAL_SWITCH_FORM_EVENT, this.lastResult);
        this.lastResult = undefined;
    },
    async snButtonGaEvent(provider) {
        await pushGaEvent('SN_CLICK', 'login', this.providerNames[provider]);
    },
    async snButtonGaEventNew(provider) {
        await pushGaEventNew({
            event: GTM_EVENTS.SN_CLICK,
            action: 'login',
            category: EVENTS_CATEGORIES.LOGIN_FORM_ELEMENTS,
            label: ['login', provider]
        });
    }
});

export default LoginExternalView;
