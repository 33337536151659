import $ from 'jquery';
import {
    Events
} from 'backbone';

import { _ } from 'wgnet-awesomejs';

import BaseErrorField from 'registration/js/base/fields/error';

import mobile from 'wgnet-awesomejs/lib/mobile';

import template from 'registration/templates/dark/fields/error.html';
import highlightLoginTemplate from 'registration/templates/dark/fields/login_highlight.html';

const emiter = Object.assign({
    pool: [],
    add(field, silent) {
        this.pool = _.unique(this.pool.concat(field));
        if (!silent) {
            this.trigger('addhint', { field });
        }
    },
    remove(field) {
        this.pool = _.reject(this.pool, (_field) => _field === field);
        this.trigger('removehint', { field });
    },
    count() {
        return this.pool.length;
    },
    contain(field) {
        return _.contains(this.pool, field);
    }
}, Events);

const ErrorField = BaseErrorField.extend({
    template,
    highlightLoginTemplate,
    errorClass: 'input-game__error',
    correctClass: 'input-game__correct',

    iconModifierPrefix: 'b-input-validate-ico__',
    initEvents(...args) {
        BaseErrorField.prototype.initEvents.apply(this, args);
        emiter.on('addhint', this.hideHint, this);
        emiter.on('showhint', this.hideHint, this);
        emiter.on('removehint', this.showHint, this);
        if (!mobile().useMobile) {
            this.$input.on('focus', _.bind(this.showHint, this));
            this.$input.on('focusout', _.bind(this.hideHint, this));
        }

        const isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

        if (isSafari) {
            this.delegateEvents({
                'mouseover .js-prevent-hide-hint': () => this.preventHideHint = true,
                'mouseout .js-prevent-hide-hint': () => this.preventHideHint = false
            });
        }
    },
    initElements(...args) {
        BaseErrorField.prototype.initElements.apply(this, args);
        this.$hint = this.$('.js-form-field-hint-error');
    },
    showHint({ field, currentTarget }) {
        const st = this.getFieldState('suggested-state');
        const er = this.getFieldState('error-state');
        if (field === this.fieldName || !(st || er) || this.data.noErrorMessage) {
            return;
        }
        const focus =
            this.$input.is(':focus') ||
            currentTarget === this.$input[0] ||
            mobile().useMobile ||
            this.forceShowHint;
        let state;
        if (focus) {
            if (st) {
                state = 2;
            } else {
                state = er ? 1 : 0;
            }
        } else {
            state = st && !er ? 1 : 0;
        }
        this.toggleSuggestedHint({ field, state });
    },
    hideHint({ field, relatedTarget }) {
        if ($(relatedTarget).hasClass('js-prevent-hide-hint') ||
            field === this.fieldName ||
            this.$hint.hasClass('hidden') ||
            this.preventHideHint) {
            return;
        }
        const st = this.getFieldState('suggested-state');
        const er = this.getFieldState('error-state');
        const focus = this.$input.is(':focus') || emiter.lastFocus === this.fieldName;
        let state;
        if (st) {
            if (er) {
                state = 0;
            } else {
                state = focus ? 2 : 1;
            }
        } else {
            state = er && focus ? 1 : 0;
        }
        this.toggleSuggestedHint({ field, state });
    },
    toggleSuggestedHint({ state }) { // state: 0 || false = close, 2 = open, 1 = only msg open
        if (state === 0) {
            this.$hint.addClass('hidden');
            this.$hint.removeClass('field-hint-game__error');
            this.$el.trigger('field.description.show');
        } else if (state === 1) {
            this.$hint.removeClass('hidden');
            this.$hint.addClass('field-hint-game__error');
            this.$('.js-expanded').addClass('hidden');
            this.$('.js-shrinked').removeClass('hidden');
            this.$el.trigger('field.description.hide');
        } else if (state === 2) {
            this.$hint.removeClass('hidden');
            this.$hint.removeClass('field-hint-game__error');
            this.$('.js-expanded').removeClass('hidden');
            this.$('.js-shrinked').addClass('hidden');
            this.$el.trigger('field.description.hide');
        }
    },
    showSuggested(result, options) {
        this.setFieldState('suggested-state', true);
        this.setIcon();
        if (!((result.field === 'login' && !mobile().useMobile) || result.field === 'name')) {
            return;
        }
        this.renderErrors(options);
        if (result.field === 'login') {
            this.addHighlight(result.value);
        }
        emiter.add(this.fieldName);
        this.showHint({});
        if (!result.gaLogged) {
            result.gaLogged = true;
            this.sendTipGaEvent('show', options.infoMsg);
        }
    },
    hideSuggested(...args) {
        BaseErrorField.prototype.hideSuggested.apply(this, args);
        emiter.remove(this.fieldName);
        this.hideHint({});
    },
    renderErrors(...args) {
        BaseErrorField.prototype.renderErrors.apply(this, args);
        if (this.getFieldState('error-state') || this.getFieldState('suggested-state')) {
            this.$el.trigger('field.description.hide');
        }
        if (!this.data.noErrorMessage) {
            this.showHint({});
        }
    },
    hideError() {
        this.$el.trigger('field.description.show');
        BaseErrorField.prototype.hideError.call(this);
        this.hideHint({});
    }
});

export default ErrorField;
