import BaseEulaField from 'registration/js/base/fields/eula';
import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import pushGaEventNew, { GTM_EVENTS } from 'registration/js/lib/googletagmanager/new_events';

import template from 'registration/templates/dark/signin_fields/remember.html';

const RememberField = BaseEulaField.extend({
    name: 'signin-remember-field',
    template,
    openIcoClass: 'tooltip-ico-game__open',
    onChange(event) {
        const checkbox = event.currentTarget;
        const { checked, name } = checkbox;
        pushGaEvent('LOGIN_FORM_REMEMBER_ME', checked ? 'checked' : 'unchecked');
        pushGaEventNew({
            event: GTM_EVENTS.LOGIN_FORM_CHECKBOX_CLICK,
            label: [name, checked]
        });
    },
});

export default RememberField;
