import $ from 'jquery';
import {
    _
} from 'wgnet-awesomejs';

import BaseInput from 'registration/js/base/fields/input';
import template from 'registration/templates/dark/signin_fields/password.html';

import pushGaEventNew, { GTM_EVENTS } from 'registration/js/lib/googletagmanager/new_events';

const PasswordField = BaseInput.extend({
    name: 'signin-password-field',
    fieldName: 'password',
    template,
    afterInitialize(...args) {
        BaseInput.prototype.afterInitialize.apply(this, args);
        const $passwordField = this.$('input');
        const $passwordFieldWrapper = $passwordField.parent();
        const $passwordFieldSet = $passwordField.parents('fieldset');
        const $passwordEye = $('.js-password-eye', $passwordFieldSet);
        const passwordEyeBemBlock = $passwordEye.data('bem-block');
        const passwordEyeWrModifierShow = $passwordEye.data('bem-wrapper-modifier-show');
        const passwordEyeModifierShow = $passwordEye.data('bem-modifier-show');
        const passwordEyeShowClass = `${passwordEyeBemBlock}__${passwordEyeModifierShow}`;
        $passwordFieldWrapper.addClass(passwordEyeWrModifierShow);
        $passwordEye.removeClass('hidden');

        $passwordEye.on('click', _.bind(() => {
            const currentType = $passwordField.attr('type');
            const isPasswordHidden = currentType === 'password';
            const changeType = isPasswordHidden ? 'text' : 'password';
            const eventType = isPasswordHidden ? 'show' : 'hide';

            pushGaEventNew({ event: GTM_EVENTS.PASSWORD_FIELD_STATE_CHANGE[eventType] });

            $passwordEye.toggleClass(passwordEyeShowClass);
            $passwordField.attr('type', changeType);

            // After changing field type, we must manually set focus to the last character
            const hasValue = $passwordField.val() !== '';
            if (hasValue) {
                const element = $passwordField[0];

                if (element.selectionStart || element.selectionStart === 0) {
                    const valueLength = element.value.length;

                    element.selectionStart = valueLength;
                    element.selectionEnd = valueLength;
                }
            }

            $passwordField.focus();
        }, this));
    }
});

export default PasswordField;
