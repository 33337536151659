import BaseLoginField from 'registration/js/base/fields/login';

import template from 'registration/templates/dark/signin_fields/login.html';

const LoginField = BaseLoginField.extend({
    template,
    name: 'signin-login-field'
});

export default LoginField;
