import BaseBirthdate from 'registration/js/base/fields/birthdate';

import template from 'registration/templates/dark/fields/birthdate.html';

import BirthdateDropdown from './birthdate_dropdown';

const Birthdate = BaseBirthdate.extend({
    template,
    BirthdateDropdown,
});

export default Birthdate;
