import jscAdd from 'ehogan-loader/jscmanager';

import {
    PREVENT_PREVALIDATE_KEYS
} from 'registration/js/base/registration_form_view';
import BaseLoginFormView from 'registration/js/base/login_form_view';

import ErrorBlock from 'registration/js/base/error_block';

import {
    bindLoginServerError
} from 'registration/js/lib/validators';

import abTestWrapper from 'registration/js/lib/abtest_trigger_wrapper';

import mobile from 'wgnet-awesomejs/lib/mobile';

import template from 'registration/templates/dark/login_form.html';

import ErrorField from './fields/error';
import DescriptionField from './fields/description';
import LoginField from './signin_fields/login';
import PasswordField from './signin_fields/password';
import CaptchaField from './signin_fields/captcha';
import RememberField from './signin_fields/remember';
import SubmitButton from './fields/submit';

jscAdd([
    ErrorBlock,
    DescriptionField,
    ErrorField,
    LoginField,
    PasswordField,
    CaptchaField,
    RememberField,
    SubmitButton
]);

const LoginBasicFormView = BaseLoginFormView.extend({
    template,
    formPrevalidate(event = {}) {
        const key = event.keyCode;
        if (PREVENT_PREVALIDATE_KEYS.indexOf(key) > -1) {
            return;
        }
        this.setFormToModel(this.$form, true);
        const errors = this.model.validate(this.model.attributes, { prevalidate: true });

        if (mobile().useMobile) {
            if (event.target) {
                this.$form.trigger(`${event.target.name}.clear`);
            }
            return;
        }
        if (errors) {
            this.onRegistrationError({
                responseJSON: {
                    errors
                }
            }, true);
        } else {
            this.setValidFields({});
        }
    },
    afterInitialize() {
        BaseLoginFormView.prototype.afterInitialize.call(this);
        bindLoginServerError(this.$form, 'login');
    },
    onRegistrationSuccess: abTestWrapper(
        BaseLoginFormView.prototype.onRegistrationSuccess,
        '^.*registration success.*$'
    ),
    getContext(context = {}) {
        context.powAllowed = this.model.powAllowed();
        return BaseLoginFormView.prototype.getContext.call(this, context);
    }
});


export default LoginBasicFormView;
