import $ from 'jquery';
import base from 'wgnet-awesomejs/lib/base';

import { _ } from 'wgnet-awesomejs';

import initializeGeowidgets from 'wgnet-awesomejs/lib/geowidgets';
import BaseRegistrationView from 'registration/js/base/registration_view';
import WaitingView from 'registration/js/dark/waiting_view';

import { useSimpleFlow } from 'registration/js/lib/get_splittest_flow';

import template from 'registration/templates/dark/wrapper.html';
import regTabTemplate from 'registration/templates/dark/wrapper_reg_tab.html';
import loginTabTemplate from 'registration/templates/dark/wrapper_login_tab.html';

import NewBasicFormView from './form_view';
import LoginBasicFormView from './login_form_view';
import ExternalServiceView from './external_view';
import LoginExternalServiceView from './login_external_view';

base();

const RegistrationView = BaseRegistrationView.extend({
    NewBasicFormView,
    ExternalServiceView,
    LoginExternalServiceView,
    LoginBasicFormView,
    template,
    regTabTemplate,
    getContext(context = {}) {
        const updatedContext = BaseRegistrationView.prototype.getContext.call(
            this, Object.assign(
                context, {
                    simpleMode: useSimpleFlow(),
                    branding: _.extract('Settings.Registration.branding'),
                }
            )
        );
        const regTab = regTabTemplate(updatedContext);
        const loginTab = loginTabTemplate(updatedContext);
        updatedContext.push({ regTab, loginTab });
        return updatedContext;
    }
});

if (_.extract('Settings.Registration.externalSigned')) {
    WaitingView.startWaiting(WaitingView);
}

const registrationView = new RegistrationView({
    el: '.js-content'
});
initializeGeowidgets();

// fix opera macos render
if (/^.*(Mac OS).*(OPR).*$/.test(navigator.userAgent)) {
    $(window).scrollTop(1);
}

export default registrationView;
