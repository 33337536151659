import jscAdd from 'ehogan-loader/jscmanager';

import BaseRegistrationFormView, {
    PREVENT_PREVALIDATE_KEYS
} from 'registration/js/base/registration_form_view';

import ErrorBlock from 'registration/js/base/error_block';

import {
    bindLoginServerError
} from 'registration/js/lib/validators';

import abTestWrapper from 'registration/js/lib/abtest_trigger_wrapper';

import mobile from 'wgnet-awesomejs/lib/mobile';

import template from 'registration/templates/dark/form.html';

import BirthdateField from './fields/birthdate';
import BonusCode from './fields/bonus_code';
import CaptchaField from './fields/captcha';
import CountryCodeField from './fields/country_code';
import DescriptionField from './fields/description';
import ErrorField from './fields/error';
import EulaField from './fields/eula';
import LoginField from './fields/login';
import NameField from './fields/name';
import ParentalEmailField from './fields/parental_email';
import PasswordField from './fields/password';
import RepasswordField from './fields/repassword';
import SubmitButton from './fields/submit';

import SuggestionDialogView from './suggestion_dialog_view';


jscAdd([
    DescriptionField,
    ErrorBlock,
    ErrorField,

    BirthdateField,
    BonusCode,
    CaptchaField,
    CountryCodeField,
    EulaField,
    LoginField,
    NameField,
    ParentalEmailField,
    PasswordField,
    RepasswordField,
    SubmitButton
]);

const NewBasicFormView = BaseRegistrationFormView.extend({
    template,
    SuggestionDialogView,
    formPrevalidate(event = {}) {
        const key = event.keyCode;
        if (PREVENT_PREVALIDATE_KEYS.indexOf(key) > -1) {
            return;
        }
        this.setFormToModel(this.$form, true);

        const errors = this.model.validate(this.model.attributes, {
            prevalidate: true,
            hasStrictRegistrationData: Boolean(this.strictRegistrationData),
        });

        if (mobile().useMobile) {
            if (event.target) {
                this.$form.trigger(`${event.target.name}.clear`);
            }
            return;
        }
        if (errors) {
            this.onRegistrationError({
                responseJSON: {
                    errors
                }
            }, true);
        } else {
            this.setValidFields({});
        }
    },
    afterInitialize() {
        BaseRegistrationFormView.prototype.afterInitialize.call(this);
        bindLoginServerError(this.$form, 'login');
        this.showCountryCodeConflictError();
    },
    onRegistrationSuccess: abTestWrapper(
        BaseRegistrationFormView.prototype.onRegistrationSuccess,
        '^.*registration success.*$'
    ),
    getContext(context = {}) {
        context.powAllowed = this.model.powAllowed();
        return BaseRegistrationFormView.prototype.getContext.call(this, context);
    }
});


export default NewBasicFormView;
