import {
    _, $
} from 'wgnet-awesomejs';

import BaseSuggestionView from 'registration/js/base/suggestion_view';

import question from 'registration/templates/dark/suggestion_question.html';
import edit from 'registration/templates/dark/suggestion_edit.html';

const BASE_DIALOG_CLASS = 'ui-dialog__indent-big ui-dialog__';
const DIALOG_THEMES = {
    wows: 'blue',
    default: 'dark'
};

const OVERLAY_CLASSES = {
};

const SuggestionDialogView = BaseSuggestionView.extend({
    initialize(...args) {
        const game = _.extract('Settings.Registration.gameModificator');
        this.dialogSettings.dialogClass = this.dialogClass(game);

        const overlayClass = OVERLAY_CLASSES[game];
        if (overlayClass) {
            this.dialogSettings.open = () => $('.ui-widget-overlay').addClass(overlayClass);
        }
        BaseSuggestionView.prototype.initialize.apply(this, args);
    },
    dialogClass(game) {
        const theme = DIALOG_THEMES[game] || DIALOG_THEMES.default;
        return `${BASE_DIALOG_CLASS}${theme}`;
    },
    dialogSettings: {
        position: {
            my: 'center center',
            at: 'center center',
            of: window
        },
        width: 'auto'
    },
    templates: {
        edit,
        question
    }
});

export default SuggestionDialogView;
export {
    DIALOG_THEMES,
    OVERLAY_CLASSES,
    BASE_DIALOG_CLASS
};
